<template>
  <div class="l-2col">
    <nav class="l-subnav">
      <h1>制作実績</h1>
      <p>最近の実績の一部をご覧いただけます。</p>
      <div class="side-button-set button-set-3col">
        <ul class="button-group notype">
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="ios">iPhone / iPad</button></li>
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="android">Android</button></li>
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="web">Webサイト</button></li>
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="rom">CD-ROM</button></li>
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="other">その他</button></li>
        </ul>
        <hr>
        <ul class="button-group notype">
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="in-house">自社開発実績</button></li>
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="consign">受託開発実績</button></li>
        </ul>
        <hr>
        <ul class="button-group notype">
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="planning">企画</button></li>
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="design">デザイン</button></li>
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="development">開発</button></li>
          <li><button class="btn btn-toggle" @click="setCategory($event)" data-category="maintenance">運用保守</button></li>
        </ul>
      </div>
    </nav>
    <section class="l-main">
      <div class="cards">
        <div v-for="(work, index) in works" class="card" :key="index" v-show="isShow(work.categories)">
          <div class="img"><img :src="image_base + work.image_name" alt=""></div>
          <div class="card-title">{{ work.name }}</div>
          <p>{{ work.description }}</p>
        </div>
      </div>
      <div class="mt90 ctr">
        <h2>この他にも多数実績がございますので、<br>詳しくはお問い合わせくださいませ。</h2>
        <!-- <p>会社案内にも実績を紹介しております。<br>
          <a href="/static/pdf/company_works.pdf" target="_blank" rel="noopener noreferrer">会社案内ダウンロード（3MB）</a>
        </p> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'WorksView',
  data: () => {
    return {
      current_category: [],
      image_base: '/static/upload/',
      works: []
    }
  },
  methods: {
    isShow: function (categories) {
      for (let i = 0, l = categories.length; i < l; i++) {
        if (this.current_category.indexOf(categories[i]) !== -1 || this.current_category.length === 0) {
          return true
        }
      }
      return false
    },
    setCategory: function (e) {
      e.target.classList.toggle('on')
      if (e.target.classList.contains('on')) {
        this.current_category.push(e.target.dataset.category)
      } else {
        let idx = this.current_category.indexOf(e.target.dataset.category)
        if (idx !== -1) {
          this.current_category.splice(idx, 1)
        }
      }
      // console.log(this.current_category)
    }
  },
  created: function () {
    this.$axios.get('/static/api/works.json')
      .then((response) => {
        this.works = response.data
      })
      .catch((reason) => {
        console.error('情報を取得できませんでした', reason);
      })
  },
  mounted: function () {
    window.dataLayer = window.dataLayer || []
    function gtag () {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'AW-794348514')
  }
}
</script>

<style scoped>

</style>
