<template>
  <section>
    404 Not Found
  </section>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
