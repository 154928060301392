<template>
  <div class="l-2col">
    <nav class="l-subnav">
      <h1>お問い合わせ</h1>
      <p>弊社へのお問い合わせ、ご質問、御見積のご依頼などフォームより承ります。<br>
        些細なことでも結構ですので、お気軽にご相談ください。</p>
    </nav>
    <section v-if="!isSended" class="l-main">
      <p v-if="!isInput" class="mt0 mb30">以下の内容で送信します。よろしければ「送信する」ボタンを押してください。</p>
      <form @submit.prevent="exec">
        <fieldset class="mb20">
          <legend>要件</legend>
          <div class="flx">
            <h3 :class="{ required: isInput }">ご相談種類</h3>
            <div v-if="isInput" :class="{ errmsg: isSubmitted && !validation.forms }" data-errmsg="選択してください">
              <input id="r1" :class="{ err: isSubmitted && !validation.forms }" type="radio" v-model="inputData.forms" value="1"><label for="r1">スマートフォンアプリ開発</label>
              <input id="r2" :class="{ err: isSubmitted && !validation.forms }" type="radio" v-model="inputData.forms" value="2"><label for="r2">WEB制作</label>
              <input id="r3" :class="{ err: isSubmitted && !validation.forms }" type="radio" v-model="inputData.forms" value="3"><label for="r3">CD-ROM制作</label>
              <input id="r4" :class="{ err: isSubmitted && !validation.forms }" type="radio" v-model="inputData.forms" value="4"><label for="r4">その他</label>
            </div>
            <div v-else>
              <span v-if="inputData.forms === '1'">スマートフォンアプリ開発</span>
              <span v-if="inputData.forms === '2'">WEB制作</span>
              <span v-if="inputData.forms === '3'">CD-ROM制作</span>
              <span v-if="inputData.forms === '4'">その他</span>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>お客様情報</legend>
          <div class="flx mb20">
            <h3 :class="{ required: isInput }">お名前</h3>
            <div v-if="isInput" class="col2" :class="{ errmsg: isSubmitted && (!validation.fname || !validation.lname) }" data-errmsg="入力してください">
              <input :class="{ err: isSubmitted && !validation.fname }" type="text" v-model="inputData.fname" value="" placeholder="姓">
              <input :class="{ err: isSubmitted && !validation.lname }" type="text" v-model="inputData.lname" value="" placeholder="名">
            </div>
            <div v-else><span>{{ inputData.fname }} {{ inputData.lname }}</span></div>
          </div>
          <div class="flx mb20">
            <h3>フリガナ</h3>
            <div v-if="isInput" class="col2">
              <input type="text" v-model="inputData.fkana" value="" placeholder="セイ">
              <input type="text" v-model="inputData.lkana" value="" placeholder="メイ">
            </div>
            <div v-else><span>{{ inputData.fkana }} {{ inputData.lkana }}</span></div>
          </div>
          <div class="flx mb20">
            <h3 :class="{ required: isInput }">メールアドレス</h3>
            <div v-if="isInput" :class="{ errmsg: isSubmitted && !validation.email }" data-errmsg="正しい形式で入力してください">
              <input :class="{ err: isSubmitted && !validation.email }" type="email" v-model="inputData.email" value="" placeholder="mail@example.com">
            </div>
            <div v-else><span>{{ inputData.email }}</span></div>
          </div>
          <div class="flx mb20">
            <h3>電話番号</h3>
            <div v-if="isInput">
              <input type="tel" v-model="inputData.tel" value="" maxlength="11" placeholder="090XXXXXXXX">
            </div>
            <div v-else><span>{{ inputData.tel }}</span></div>
          </div>
        </fieldset>
        <fieldset>
          <legend>その他</legend>
          <div class="flx mb20">
            <h3 :class="{ required: isInput }">お問い合わせ内容</h3>
            <div v-if="isInput" :class="{ errmsg: isSubmitted && !validation.msg }" data-errmsg="入力してください">
              <textarea :class="{ err: isSubmitted && !validation.msg }" v-model="inputData.msg" placeholder="ご相談や、ご依頼内容などを自由にご記入ください"></textarea>
            </div>
            <div v-else><span>{{ inputData.msg | nl2br }}</span></div>
          </div>
          <div class="flx">
            <h3 :class="{ required: isInput }">個人情報の取り扱い</h3>
            <div v-if="isInput" class="flx" :class="{ errmsg: isSubmitted && !validation.agreement }" data-errmsg="同意のチェックをお願いします">
              <input id="c1" :class="{ err: isSubmitted && !validation.agreement }" type="checkbox" v-model="inputData.agreement" value="1"> <label for="c1">「<a href="#" @click="openPopupLink('policy.html',$event)">個人情報の取り扱いについて</a>」同意する</label>
            </div>
            <div v-else>
              <span v-if="inputData.agreement">同意する</span>
              <span v-if="!inputData.agreement">同意しない</span>
            </div>
          </div>
        </fieldset>
        <p v-if="isInput" class="action-area"><button class="btn btn-submit" type="submit">確認ページへ</button></p>
        <p v-else class="action-area flx-between">
          <button class="btn btn-return" type="button" @click.prevent="returnInput">戻る</button>
          <button class="btn btn-submit" type="submit" name="submit">送信する</button>
        </p>
      </form>
    </section>

    <section v-if="isSended" class="l-main">
      <p>内容を送信いたしました。<br>
      お問い合わせいただきましてありがとうございます。<br>
      折り返し担当者からご連絡させていただきます。</p>
    </section>
  </div>
</template>

<script>
const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  name: 'ContactView',
  data () {
    return {
      isInput: true,
      isSended: true,
      isSubmitted: false,
      errors: [],
      inputData: {
        forms: null,
        fname: '',
        lname: '',
        fkana: '',
        lkana: '',
        email: '',
        tel: '',
        msg: '',
        agreement: null
      }
    }
  },
  computed: {
    validation: function () {
      return {
        forms: !!this.inputData.forms,
        fname: !!this.inputData.fname.trim(),
        lname: !!this.inputData.lname.trim(),
        email: emailRE.test(this.inputData.email.toLowerCase()),
        msg: !!this.inputData.msg,
        agreement: !!this.inputData.agreement
      }
    },
    isValid: function () {
      let validation = this.validation
      return Object.keys(validation).every(function (key) {
        return validation[key]
      })
    }
  },
  methods: {
    returnInput: function () {
      this.isInput = true
    },
    exec: function () {
      this.isSubmitted = true
      if (this.isInput) {
        if (this.isValid) {
          console.log('valid')
          this.isInput = false
        } else {
          console.log('error')
        }
      } else {
        let formdata = new URLSearchParams()
        formdata.append('kind', 'contact')
        for (let key of Object.keys(this.inputData)) {
          formdata.append(key, this.inputData[key])
        }

        this.$axios.post('/static/api/contact.php', formdata)
          .then(() => {
            this.isSended = true

            /* Event snippet for お問い合わせ完了 conversion page */
            window.dataLayer = window.dataLayer || []
            function gtag () {
              window.dataLayer.push(arguments)
            }
            gtag('js', new Date())
            gtag('config', 'AW-794348514')
            gtag('event', 'conversion', {'send_to': 'AW-794348514/njW6CMeuypYBEOKX4_oC'})
          })
          .catch((reason) => {
            console.error('情報を取得できませんでした', reason)
          })
      }
    },
    openPopupLink: function (target, e) {
      e.preventDefault()
      window.open(target, '', 'width=500,height=700,scrollbars=1')
    }
  },
  created: function () {
    this.isInput = true
    this.isSended = false
    this.isSubmitted = false
    this.errors = []
    this.inputData = {
      forms: null,
      fname: '',
      lname: '',
      fkana: '',
      lkana: '',
      email: '',
      tel: '',
      msg: '',
      agreement: null
    }
  },
  mounted: function () {
    /* Google Ad */
    window.dataLayer = window.dataLayer || []
    function gtag () {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'AW-794348514')

    /* Facebook Pixel Code */
    let f = window
    let b = document
    let e = 'script'
    let v = 'https://connect.facebook.net/en_US/fbevents.js'
    let n, t, s
    if (f.fbq) return
    n = f.fbq = function () {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) {
      f._fbq = n
      n.push = n
      n.loaded = !0
      n.version = '2.0'
      n.queue = []
      t = b.createElement(e)
      t.async = !0
      t.src = v
      s = b.getElementsByTagName(e)[0]
      s.parentNode.insertBefore(t, s)
    }
    t.addEventListener('load', function () {
      window.fbq('init', '536814713398157')
      window.fbq('track', 'PageView')
    })
  },
  filters: {
    nl2br: function (value) {
      return value.replace('\n', '<br>')
    }
  },
}
</script>

<style scoped>

</style>
