<template>
  <footer class="l-footer">
    <div class="l-pageup"><a href="#" aria-label="ページトップへ" @click="goPagetop($event)"><i class="fa-solid fa-angle-up"></i></a></div>
    <div class="footer-nav">
      <ul class="notype flx">
        <li><router-link to="/privacy">個人情報保護方針</router-link></li>
        <li><a href="#" @click="openPopupLink('policy.html',$event)">個人情報の取り扱いについて</a></li>
      </ul>
      <address>
        プライバシーマーク：登録番号第10824440号<br>
        <small>&copy; 2001 electrodream,Inc.</small>
      </address>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'MainFooter',
  methods: {
    openPopupLink: function (target, e) {
      e.preventDefault()
      window.open(target, '', 'width=500,height=700,scrollbars=1')
    },
    goPagetop: function (event) {
      event.preventDefault()
      this.$SmoothScroll(0, 400, null, null, 'y')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
