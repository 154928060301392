<template>
  <section class="l-1col">
    <header>
      <h1>個人情報保護方針</h1>
    </header>
    <div class="box">
      <p class="mt0 mb20">株式会社エレクトロドリームは、当社の業務である様々なシステムソリューションの提供において取り扱う個人情報を、適切に保護することの社会的使命を十分に認識した上で、社会的要請の変化、経営環境の変動等について個人情報保護の仕組みを継続的に改善し、個人情報の保護に全社を挙げて取り組むことを宣言致します。</p>
      <section>
        <h2>個人情報の適用範囲</h2>
        <p>
          個人情報は当社の行う以下の情報サービス関連業務
          <em class="box">Webサイト、スマートフォンアプリケーション企画・開発・運用・コンサルティング・パッケージソフトの企画・開発／デジタルコンテンツの制作・販売業務</em><br>
          を正当に遂行する上で必要な範囲に限定して、取得・利用・提供します。<br>
          なお、これには業務上における受託個人情報も含みます。<br>
          目的外利用は行いません。目的外の利用を行わないための措置を講じます。
        </p>
      </section>

      <section>
        <h2>個人情報セキュリティ体制の向上</h2>
        <p>個人情報への不正アクセスまたはその滅失・き損及び漏洩等のリスクに対しては、合理的な安全対策及び予防・是正処置を講じ、事業の実情に合致した経営資源を注入し個人情報セキュリティ体制を継続的に向上させます。</p>
      </section>

      <section>
        <h2>法令・規範の遵守</h2>
        <p>個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守します。</p>
      </section>

      <section>
        <h2>苦情・相談の対応</h2>
        <p>当社の個人情報取扱いに関する苦情・相談・問合せについては苦情相談窓口を常設し、適切かつ迅速に対応します。</p>
      </section>

      <section>
        <h2>個人情報保護マネジメントシステムの改善</h2>
        <p>社内の日常的な点検や、当社を取り巻く環境の変化、社会的な要求などを踏まえ、適時・適切にマネジメントシステムを見直しその改善を継続的に推進します。</p>
      </section>

      <section>
        <h2>個人情報保護方針に関するお問合せ</h2>
        <p>個人情報保護&nbsp;&nbsp;苦情・相談窓口<br>Tel：03-5860-4131<br>メール：info&#64;electrodream.co.jp</p>
      </section>

      <section class="mb20">
        <p class="txt_r">
          制定日：2016年 5月 １日<br>
          最終改定日：2020年12月15日<br>
          株式会社エレクトロドリーム<br>
          代表取締役&nbsp;&nbsp;務台 祐太郎
        </p>
      </section>
    </div><!-- //#content// -->
  </section>
</template>

<script>
export default {
  name: 'PrivacyView',
  mounted: function () {
    window.dataLayer = window.dataLayer || []
    function gtag () {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'AW-794348514')
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
