<template>
  <nav class="l-nav">
    <ul class="nav-list" :class="{home: this.$route.name === 'Home'}">
      <top-nav from="navbar" />
      <li v-if="$route.name !== 'Home'" class="home"><router-link to="/" exact><span>ホーム</span><i class="fa-sharp fa-solid fa-house"></i></router-link></li>
      <li><router-link to="/works"><span>制作実績</span><i class="fa-solid fa-tags"></i></router-link></li>
      <li><router-link to="/recruit"><span>採用情報</span><i class="fa-solid fa-users"></i></router-link></li>
      <li><router-link to="/contact"><span>お問い合わせ</span><i class="fa-solid fa-envelope"></i></router-link></li>
      <li class="external"><a href="https://www.facebook.com/electrodreamInc/" target="_blank" rel="noopener noreferrer"><span>Facebook</span><i class="fa-brands fa-facebook-f"></i></a></li>
    </ul>
  </nav>
</template>

<script>
import TopNav from './TopNav'

export default {
  name: 'NavBar',
  components: {
    TopNav
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
