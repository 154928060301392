<template>
  <div id="app" class="l-wrapper">
    <vue-header />
    <div class="hamburger" ref="btnHamburger" @click="openMenu($event)"></div>
    <vue-menu ref="menu" @closeMenu="closeMenu" />
    <main class="l-content">
      <transition name="router-transition">
        <router-view />
      </transition>
    </main>
    <vue-navbar @open-menu="openMenu" />
    <vue-footer />
  </div>
</template>

<script>
// compornents
import VueHeader from '@/components/MainHeader'
import VueMenu from '@/components/MenuView'
import VueNavbar from '@/components/NavBar'
import VueFooter from '@/components/MainFooter'

export default {
  name: 'App',
  methods: {
    openMenu: function (e) {
      e.target.classList.toggle('on')
      this.$refs.menu.openMenu(e.target.classList.contains('on'))
    },
    closeMenu: function () { // Menu内リンク遷移時にMenuを閉じる場合、hamburgerボタンを戻す
      this.$refs.btnHamburger.classList.remove('on')
    }
  },
  created: function () {
    // ページタイトル
    this.$router.afterEach((to) => {
      if (to.meta && to.meta.title) {
        document.title = to.meta.title
      }
    })
  },
  mounted: function () {
    if (this.$route.meta && this.$route.meta.title) {
      document.title = this.$route.meta.title
    }
  },
  components: {
    VueHeader,
    VueMenu,
    VueNavbar,
    VueFooter
  }
}

</script>

<style scoped>
.router-transition-enter-active {
  animation: fadeIn .3s;
  opacity: 0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.router-transition-leave-active {
  animation: fadeOut .3s;
}
@keyframes fadeOut {
  to {
    opacity: 0;
  }
}
</style>
