// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import VueAnalytics from 'vue-analytics'
import axios from 'axios'

// css
import './assets/scss/app.scss'

// lib
import smoothScroll from 'vue-smoothscroll'
Vue.use(smoothScroll)

Vue.config.productionTip = false
Vue.prototype.$axios = axios

Vue.use(VueAnalytics, {
  id: 'UA-3699795-1',
  router
})

new Vue({
  el: '#app',
  router,
  components: { App },
  render (h) {
    return h(App)
  }
})
