import Vue from 'vue'
import Router from 'vue-router'

// page
import Home from '@/views/HomeView'
import Works from '@/views/WorksView'
import Recruit from '@/views/RecruitView'
import Contact from '@/views/ContactView'
import Privacy from '@/views/PrivacyView'
import NotFound from '@/views/404'

Vue.use(Router)

const router = new Router({
  mode: 'history', // .htaccess が必要（https://router.vuejs.org/ja/guide/essentials/history-mode.html）
  routes: [
    { // TOPページ
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title: '株式会社エレクトロドリーム(electrodream,Inc.) | 東京都のWeb制作,UI/UXデザイン,アプリ開発会社'
      }
    },
    { // 実績
      path: '/works',
      name: 'Works',
      component: Works,
      meta: {
        title: '制作実績 | 株式会社エレクトロドリーム(electrodream,Inc.)'
      }
    },
    { // 採用
      path: '/recruit',
      name: 'Recruit',
      component: Recruit,
      meta: {
        title: '採用情報 | 株式会社エレクトロドリーム(electrodream,Inc.)'
      }
    },
    { // お問い合わせ
      path: '/contact',
      name: 'Contact',
      component: Contact,
      meta: {
        title: 'お問い合わせ | 株式会社エレクトロドリーム(electrodream,Inc.)'
      }
    },
    { // 個人情報保護方針
      path: '/privacy',
      name: 'Privacy',
      component: Privacy,
      meta: {
        title: '個人情報保護方針 | 株式会社エレクトロドリーム(electrodream,Inc.)'
      }
    },
    { // 404
      path: '*',
      name: '404 Not Found',
      component: NotFound,
      meta: {
        title: '404 Not Found | 株式会社エレクトロドリーム(electrodream,Inc.)'
      }
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) { // ブラウザの戻るで戻った時に前回のポジションが帰ってくる
      return savedPosition
    } else {
      const position = {}

      // ハッシュがついていたら
      if (to.hash) {
        position.selector = to.hash

        if (document.querySelector(to.hash)) {
          return position
        }

        return false
      }

      return { x: 0, y: 0 }
    }
  }
})

export default router
