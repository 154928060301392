<template>
  <li v-if="$route.name === 'Home'" class="anchor-links-wrap">
    <ul class="anchor-links">
      <li :class="{current: currentNavIndex === 0}"><a href="#app" @click="innerLink(0, $event)"><span :class="{show: from === 'menu'}">TOP</span></a></li>
      <li :class="{current: currentNavIndex === 1}"><a href="#service" @click="innerLink('service', $event)"><span :class="{show: from === 'menu'}">Service</span></a></li>
      <li :class="{current: currentNavIndex === 2}"><a href="#others" @click="innerLink('others', $event)"><span :class="{show: from === 'menu'}">Internal Ventures</span></a></li>
      <li :class="{current: currentNavIndex === 3}"><a href="#contact" @click="innerLink('contact', $event)"><span :class="{show: from === 'menu'}">Contact us</span></a></li>
      <li :class="{current: currentNavIndex === 4}"><a href="#company" @click="innerLink('company', $event)"><span :class="{show: from === 'menu'}">Company</span></a></li>
    </ul>
  </li>
</template>

<script>
export default {
  name: 'TopNav',
  props: ['from'],
  data () {
    return {
      navBtns: [],
      linkTargetPosList: [],
      currentNavIndex: 0
    }
  },
  methods: {
    innerLink: function (target, event) {
      event.preventDefault()
      let postarget = (isNaN(target)) ? document.querySelector('#' + target) : target
      this.$SmoothScroll(postarget, 400, null, null, 'y')
      this.$emit('innerLink', target)
    },
    tick: function () {
      for (let i = this.linkTargetPosList.length; i >= 0; i--) {
        if (Math.abs(-document.body.getBoundingClientRect().top) >= this.linkTargetPosList[i]) {
          this.currentNavIndex = i
          break
        }
      }
      requestAnimationFrame(this.tick)
    },
    setElementPosition: function () {
      let _self = this
      this.navBtns.forEach(function (item) {
        let elem = document.querySelector(item.children[0].getAttribute('href'))
        _self.linkTargetPosList.push(_self.getAbsolutePosition(elem).top)
      })
    },
    // 要素のページ内での絶対座標を取得
    getAbsolutePosition: function (elm) {
      const {left, top} = elm.getBoundingClientRect()
      const {left: bleft, top: btop} = document.body.getBoundingClientRect()
      return {
        left: left - bleft,
        top: top - btop
      }
    }
  },
  mounted: function () {
    this.navBtns = [].slice.call(document.querySelectorAll('.l-nav .anchor-links li'))
    this.setElementPosition()
    window.addEventListener('resize orientationchange', this.setElementPosition)
    requestAnimationFrame(this.tick)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
