<template>
  <div class="l-2col">
    <nav class="l-subnav">
      <h1>採用情報</h1>
      <p>ただいま随時募集を行っております。私たちと一緒に働いてみませんか。<br>
        ご興味のある方は詳細をお問い合わせください。<br>
        またフリーランスの方との協業も積極的に行っております。</p>
      <!--
      <h2>募集職種</h2>
      <p>現在、<strong>アプリ開発に興味のあるディレクター、エンジニア、デザイナー</strong>を募集をしています。募集中の社員採用情報につきましては、以下の求人サイトをご覧ください。</p>
      <div class="side-button-set">
        <ul class="button-group notype">
          <li><a class="btn btn-link" href="https://gooca.works/company/18530/" target="_blank" rel="noopener noreferrer">グーカ</a></li>
          <li><a class="btn btn-link" href="https://www.wantedly.com/companies/electrodream/projects" target="_blank" rel="noopener noreferrer">Wantedly</a></li>
          <li><a class="btn btn-link" href="https://job.j-sen.jp/search/company_14351.htm" target="_blank" rel="noopener noreferrer">転職ナビ</a></li>
          <li><a class="btn btn-link" href="https://next.rikunabi.com/company/cmi2108349001/" target="_blank" rel="noopener noreferrer">リクナビNEXT</a></li>
        </ul>
      </div>
      -->
    </nav>
    <section v-if="!isSended" class="l-main">
      <p v-if="!isInput" class="mt0 mb30">以下の内容で送信します。よろしければ「送信する」ボタンを押してください。</p>
      <form @submit.prevent="exec">
        <fieldset class="mb20">
          <legend>要件</legend>
          <div>
            <h3 :class="{ required: isInput }">雇用形態</h3>
            <div v-if="isInput" :class="{ errmsg: isSubmitted && !validation.forms }" data-errmsg="選択してください">
              <input id="r1" :class="{ err: isSubmitted && !validation.forms }" type="radio" v-model="inputData.forms" value="1"><label for="r1">正社員</label>
              <input id="r2" :class="{ err: isSubmitted && !validation.forms }" type="radio" v-model="inputData.forms" value="2"><label for="r2">アルバイト</label>
            </div>
            <div v-else>
              <span v-if="inputData.forms === '1'">正社員</span>
              <span v-if="inputData.forms === '2'">アルバイト</span>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>お客様情報</legend>
          <div class="mb20">
            <h3 :class="{ required: isInput }">お名前</h3>
            <div v-if="isInput" class="col2" :class="{ errmsg: isSubmitted && (!validation.fname || !validation.lname) }" data-errmsg="入力してください">
              <input :class="{ err: isSubmitted && !validation.fname }" type="text" v-model="inputData.fname" value="" placeholder="姓">
              <input type="text" :class="{ err: isSubmitted && !validation.lname }" v-model="inputData.lname" value="" placeholder="名">
            </div>
            <div v-else><span>{{ inputData.fname }} {{ inputData.lname }}</span></div>
          </div>
          <div class="mb20">
            <h3>フリガナ</h3>
            <div v-if="isInput" class="col2">
              <input type="text" v-model="inputData.fkana" value="" placeholder="セイ">
              <input type="text" v-model="inputData.lkana" value="" placeholder="メイ">
            </div>
            <div v-else><span>{{ inputData.fkana }} {{ inputData.lkana }}</span></div>
          </div>
          <div class="mb20">
            <h3 :class="{ required: isInput }">メールアドレス</h3>
            <div v-if="isInput" :class="{ errmsg: isSubmitted && !validation.email }" data-errmsg="正しい形式で入力してください">
              <input :class="{ err: isSubmitted && !validation.email }" type="email" v-model="inputData.email" value="" placeholder="mail@example.com">
            </div>
            <div v-else><span>{{ inputData.email }}</span></div>
          </div>
          <div class="mb20">
            <h3>電話番号</h3>
            <div v-if="isInput">
              <input type="tel" v-model="inputData.tel" value="" maxlength="11" placeholder="090XXXXXXXX">
            </div>
            <div v-else><span>{{ inputData.tel }}</span></div>
          </div>
        </fieldset>
        <fieldset>
          <legend>その他</legend>
          <div class="mb20">
            <h3>その他</h3>
            <div v-if="isInput">
              <textarea v-model="inputData.msg" placeholder="何かありましたらご記入ください（質問、アピールなど）"></textarea>
            </div>
            <div v-else><span>{{ inputData.msg | nl2br }}</span></div>
          </div>
          <div>
            <h3 :class="{ required: isInput }">個人情報の取り扱い</h3>
            <div v-if="isInput" class="flx" :class="{ errmsg: isSubmitted && !validation.agreement }" data-errmsg="同意のチェックをお願いします">
              <input id="c1" :class="{ err: isSubmitted && !validation.agreement }" type="checkbox" v-model="inputData.agreement" value="1"> <label for="c1">「<a href="#" @click="openPopupLink('policy.html',$event)">個人情報の取り扱いについて</a>」同意する</label>
            </div>
            <div v-else>
              <span v-if="inputData.agreement">同意する</span>
              <span v-if="!inputData.agreement">同意しない</span>
            </div>
          </div>
        </fieldset>
        <p v-if="isInput" class="action-area"><button class="btn btn-submit" type="submit">確認ページへ</button></p>
        <p v-else class="action-area flx-between">
          <button class="btn btn-return" type="button" @click.prevent="returnInput">戻る</button>
          <button class="btn btn-submit" type="submit" name="submit">送信する</button>
        </p>
      </form>
    </section>

    <section v-if="isSended" class="l-main">
      <p>内容を送信いたしました。<br>
      お問い合わせいただきましてありがとうございます。<br>
      折り返し担当者からご連絡させていただきます。</p>
    </section>
  </div>
</template>

<script>
const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  name: 'RecruitView',
  data () {
    return {
      isInput: true,
      isSended: false,
      isSubmitted: false,
      errors: [],
      inputData: {
        forms: null,
        fname: '',
        lname: '',
        fkana: '',
        lkana: '',
        email: '',
        tel: '',
        msg: '',
        agreement: null
      }
    }
  },
  computed: {
    validation: function () {
      return {
        forms: !!this.inputData.forms,
        fname: !!this.inputData.fname.trim(),
        lname: !!this.inputData.lname.trim(),
        email: emailRE.test(this.inputData.email.toLowerCase()),
        agreement: !!this.inputData.agreement
      }
    },
    isValid: function () {
      let validation = this.validation
      return Object.keys(validation).every(function (key) {
        return validation[key]
      })
    }
  },
  methods: {
    returnInput: function () {
      this.isInput = true
    },
    exec: function () {
      this.isSubmitted = true
      if (this.isInput) {
        console.log(this.inputData)
        if (this.isValid) {
          console.log('valid')
          this.isInput = false
        } else {
          console.log('error')
        }
      } else {
        let formdata = new URLSearchParams()
        formdata.append('kind', 'recruit')
        for (let key of Object.keys(this.inputData)) {
          formdata.append(key, this.inputData[key])
        }

        this.$axios.post('/static/api/contact.php', formdata)
          .then(() => {
            this.isSended = true
          })
          .catch((reason) => {
            console.error('情報を取得できませんでした', reason)
          })
      }
    },
    openPopupLink: function (target, e) {
      e.preventDefault()
      window.open(target, '', 'width=500,height=700,scrollbars=1')
    }
  },
  created: function () {
    this.isInput = true
    this.isSended = false
    this.isSubmitted = false
    this.errors = []
    this.inputData = {
      forms: null,
      fname: '',
      lname: '',
      fkana: '',
      lkana: '',
      email: '',
      tel: '',
      msg: '',
      agreement: null
    }
  },
  mounted: function () {
    window.dataLayer = window.dataLayer || []
    function gtag () {
      window.dataLayer.push(arguments)
    }
    gtag('js', new Date())
    gtag('config', 'AW-794348514')
  },
  filters: {
    nl2br: function (value) {
      return value.replace('\n', '<br>')
    }
  },
}
</script>

<style scoped>

</style>
